import * as types from './types';
import * as quizStatApi from '../../api/quizStatApi';

export function loadQuizStatSuccess(payload) {
  return { type: types.LOAD_QUIZ_STAT_SUCCESS, payload };
}

export function createQuizStatSuccess(payload) {
  return { type: types.CREATE_QUIZ_STAT_SUCCESS, payload };
}

export function updateQuizStatSuccess(payload) {
  return { type: types.UPDATE_QUIZ_STAT_SUCCESS, payload };
}

export function invalidateQuizStatState() {
  return { type: types.INVALIDATE_QUIZ_STAT_STATE };
}

export function getQuizStat(params = {}) {
  return function (dispatch) {
    return quizStatApi.getQuizStat(params).then((res) => {
      dispatch(loadQuizStatSuccess(res));
      return res;
    });
  };
}

export function createQuizStat(params = {}) {
  return function (dispatch) {
    return quizStatApi.createQuizStat(params).then((res) => {
      const { quiz_stat } = res;

      dispatch(createQuizStatSuccess(quiz_stat));
      return res;
    });
  };
}
