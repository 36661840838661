import logoImg from '../../assets/images/logo-light-sm.png';

const LegacyNavbar = () => {
  return (
    <nav className='navbar navbar-dark bg-primary'>
      <span className='navbar-brand px-4 px-md-0 offset-md-1'>
        <img src={logoImg} height={40} width={121} alt='Logo' />
      </span>
    </nav>
  );
};

export default LegacyNavbar;
