import { Link } from 'react-router-dom';
import AttachmentThumb from '../../components/attachment-thumb/AttachmentThumb';
import Layout from '../../components/layout/LegacyLayout';
import useShareThis from '../../libs/hooks/useShareThis';
import './HomePage.css';

const HomePage = () => {
  useShareThis();

  return (
    <Layout>
      <div className='home-page'>
        <div className='container py-0'>
          <p>Discover your Attachment Style in 5 minutes</p>
          <h1 className='title'>Attachment Style Test</h1>
          <div className='row justify-content-center'>
            <div className='col-6 col-md-3'>
              <AttachmentThumb attachment='secure' />
              <p className='mt-3 mb-4'>Secure</p>
            </div>
            <div className='col-6 col-md-3'>
              <AttachmentThumb attachment='anxious' />
              <p className='mt-3 mb-4'>Anxious</p>
            </div>
            <div className='col-6 col-md-3'>
              <AttachmentThumb attachment='avoidant' />
              <p className='mt-3 mb-4'>Avoidant</p>
            </div>
            <div className='col-6 col-md-3'>
              <AttachmentThumb attachment='disorganized' />
              <p className='mt-3 mb-4'>Disorganized</p>
            </div>
          </div>
          <Link
            to='/relationship-structures/situation'
            className='btn btn-primary w-100 start-quiz-btn'
          >
            Start Quiz
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
