import { useState } from 'react';
import { ageList, AGE_UNDER_18 } from '../libs/helpers/ageHelper';
import RadioList from './radio-list/RadioList';
import ParentalConsent from './parental-consent/ParentalConsent';

interface IOption {
  label: string;
  value: any;
}

interface Props {
  age: IOption;
  setAge: (option: IOption | null) => void;
  hideUnderage?: boolean;
}

const AgePicker: React.FC<Props> = ({ age, setAge, hideUnderage = false }) => {
  const [showParentalConsent, setShowParentalConsent] = useState(false);

  const ageOptions: IOption[] = Object.entries(ageList)
    .map(([value, label]) => ({
      label,
      value,
    }))
    .filter(
      ({ value }: IOption) =>
        !hideUnderage || (hideUnderage && parseInt(value) !== AGE_UNDER_18),
    );

  const onChange = (option: IOption | null) => {
    if (option?.value !== age?.value) {
      const selectedAge = parseInt(option?.value);

      selectedAge === AGE_UNDER_18
        ? setShowParentalConsent(true)
        : setAge(option);
    }
  };

  const onParentalConsentGiven = () => {
    const option: IOption | null =
      ageOptions.find((o) => parseInt(o.value) === AGE_UNDER_18) || null;

    setAge(option);
    setShowParentalConsent(false);
  };

  return (
    <>
      <RadioList
        name='age'
        options={ageOptions}
        selectedOption={age}
        onChange={onChange}
      />
      <ParentalConsent
        isOpened={showParentalConsent}
        setIsOpened={setShowParentalConsent}
        onConfirm={onParentalConsentGiven}
      />
    </>
  );
};

export default AgePicker;
