import { Link } from 'react-router-dom';
import env from '../../env';
import './PageNotFound.css';

const PageNotFound = () => {
  return (
    <div className='page-not-found'>
      <div className='code'>404</div>
      <h1 className='message'>Oops ! Page not found.</h1>
      <Link to={env.wordpressUrl} className='btn btn-secondary w-50 mt-5'>
        Home
      </Link>
    </div>
  );
};

export default PageNotFound;
