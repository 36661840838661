import Footer from '../footer/LegacyFooter';
import Navbar from '../navbar/LegacyNavbar';
import styles from './legacy-layout.module.css';

const LegacyLayout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Navbar />
      <div className={styles.content}>{children}</div>
      <Footer />
    </div>
  );
};

export default LegacyLayout;
