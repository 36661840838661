import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import useDebounce from '../../../libs/hooks/useDebounce';
import {
  createOrUpdateAnswer,
  createOrUpdateAnswerSuccess,
} from '../../../libs/redux/actions/userAnswerActions';
import Answer from '../../../interfaces/Answer';
import UserAnswer from '../../../interfaces/UserAnswer';
import RangeSlider from '../../range-slider/RangeSlider';

interface Props {
  questionId: number;
  surveyStatId: number;
  answers: Answer[];
  selectedUserAnswer: UserAnswer | undefined;
  createOrUpdateAnswer: (params?: object) => Promise<any>;
  createOrUpdateAnswerSuccess: any;
  disabled: boolean;
}

const UserAnswerForm: React.FC<Props> = ({
  questionId,
  surveyStatId,
  answers,
  selectedUserAnswer,
  createOrUpdateAnswer,
  createOrUpdateAnswerSuccess,
  disabled,
}) => {
  const debounce = useDebounce();

  const selectedAnswer = answers.find(
    (a) => a.id === selectedUserAnswer?.answer_id,
  );

  const setOptionHandler = ({ value }: any) => {
    const params = {
      survey_stat_id: surveyStatId,
      question_id: questionId,
      answer_id: value,
    };

    createOrUpdateAnswerSuccess(params);

    debounce(
      `question-${questionId}`,
      () => {
        createOrUpdateAnswer(params).catch(({ message }) => {
          toast.error(message);
        });
      },
      300,
    );
  };

  return (
    <RangeSlider
      name={`answer_${questionId}`}
      options={answers.map((a) => ({
        label: a.name,
        label_html: a.name_html,
        value: a.id,
      }))}
      selectedOption={
        selectedAnswer
          ? {
              label: selectedAnswer.name,
              label_html: selectedAnswer.name_html,
              value: selectedAnswer.id,
            }
          : null
      }
      setSelectedOption={setOptionHandler}
      disabled={disabled}
    />
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createOrUpdateAnswer,
  createOrUpdateAnswerSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAnswerForm);
