import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/emotional-regulation/QuizResult';
import image from '../../assets/images/report/emotional-regulation.png';

export class EmotionalRegulation implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score, redirectUrl }: any) {
    return {
      subtitle: 'Emotional Dysregulation Score:',
      title: `${score}%`,
      description:
        'Read the full report to see the breakdown of your emotional dysregulation scores.',
      bulletPoints: [
        'Free 9 Page Report with your results compared to a global population.',
        'Complete breakdown of the domains: non-acceptance of emotional responses, difficulties in goal-directed behavior, impulse control difficulties, limited access to effective emotion regulation strategies, and emotional clarity.',
      ],
      color: '#e7716d',
      backgroundColor: '#f3b6b4',
      redirectUrl,
      image,
    };
  }
}
