import { useEffect } from 'react';
import env from '../../env';

const useShareThis = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = env.shareThisUrl;
    script.async = true;

    document.head.appendChild(script);
    window?.__sharethis__?.initialize();
    document.body.classList.remove('pb-0');

    return () => {
      document.head.removeChild(script);
      document.querySelector('.st-sticky-share-buttons')?.remove();
      document.body.classList.add('pb-0');
    };
  });
};

export default useShareThis;
