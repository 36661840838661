import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
import './Navbar.css';

interface Action {
  content: string;
  onClick: () => Function;
  props?: () => object;
}

interface Props {
  leftAction: Action;
  rightAction?: Action;
}

const Navbar: React.FC<Props> = ({ leftAction, rightAction }) => {
  return (
    <div className='quiz-navbar'>
      <div className='container p-0'>
        <div className='d-flex align-items-center justify-content-between'>
          <button
            className='btn btn-link p-3 d-flex align-items-center fs-15'
            {...leftAction.props}
            onClick={leftAction.onClick}
          >
            <ArrowLeftIcon className='me-1' />
            {leftAction.content}
          </button>
          {rightAction && (
            <button
              className='btn btn-link p-3 d-flex align-items-center fs-15'
              {...rightAction.props}
              onClick={rightAction.onClick}
            >
              {rightAction.content}
              <ArrowRightIcon className='ms-1' />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
