import { useEffect, useState } from 'react';
import Modal from '../../modal/Modal';
import './Disclaimer.css';

const Disclaimer = ({ isOpened, setIsOpened }: any) => {
  const [policyConsent, setPolicyConsent]: any = useState(false);
  const [recieveEmailsConsent, setRecieveEmailsConsent]: any = useState(false);

  const onContinue = () => {
    if (policyConsent && recieveEmailsConsent) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    setPolicyConsent(false);
    setRecieveEmailsConsent(false);
  }, [isOpened]);

  return (
    <Modal
      id='compare-disclaimer'
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      data-bs-backdrop='static'
    >
      <Modal.Header showCloseButton={false}>
        <h5 className='mb-0'>Comparison Quiz</h5>
      </Modal.Header>
      <Modal.Body>
        <p>
          This quiz analyzes your results compared to your previous responses to
          help you understand how attachment patterns may change over time.
        </p>
        <p>
          Results can fluctuate based on your current life circumstances, and
          these quizzes are not diagnostic tools.
        </p>
        <p>
          Your report includes a new breakdown of patterns associated with each
          attachment style, both in romantic and general contexts, along with a
          comparison of your results over time.
        </p>
        <p>
          We’ve also included optional self-report assessments on Emotion
          Regulation and Self-Esteem to help you gain more insight.
        </p>
      </Modal.Body>
      <Modal.Footer className='modal-footer pt-0 justify-content-between'>
        <div>
          <div className='form-check'>
            <input
              id='policy-agree'
              type='checkbox'
              className='form-check-input'
              value='on'
              onChange={(e: any) => setPolicyConsent(e.target.checked)}
              checked={policyConsent}
            />
            <label className='form-check-label' htmlFor='policy-agree'>
              I agree to Privacy Policy & Terms and Conditions
            </label>
          </div>
          <div className='form-check'>
            <input
              id='recieve-emails-agree'
              type='checkbox'
              className='form-check-input'
              value='on'
              onChange={(e: any) => setRecieveEmailsConsent(e.target.checked)}
              checked={recieveEmailsConsent}
            />
            <label className='form-check-label' htmlFor='recieve-emails-agree'>
              I agree to that The Attachment Project can send me emails
            </label>
          </div>
        </div>
        <Modal.ActionButton
          className='btn btn-secondary btn-agree'
          onClick={onContinue}
          disabled={!policyConsent || !recieveEmailsConsent}
        >
          Continue
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
};

export default Disclaimer;
