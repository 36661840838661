import { connect } from 'react-redux';
import { updateQuizStat } from '../../../libs/redux/actions/coupleQuizStatActions';
import QuestionContainer from '../../question-container/QuestionContainer';
import AgePicker from '../../AgePicker';
import CountryStatePicker from '../../CountryStatePicker';
import RadioList from '../../radio-list/RadioList';
import SubmitButton from '../../SubmitButton';
import useCoupleInviteeForm from '../hooks/useCoupleInviteeForm';

const CoupleInviteeForm = (props: any) => {
  const {
    isSubmitting,
    age,
    setAge,
    gender,
    setGender,
    kids,
    setKids,
    setLocation,
    genderOptions,
    kidsOptions,
    submitForm,
    questionIndex,
    setQuestionIndex,
  } = useCoupleInviteeForm(props);

  const onSubmit = (e: any) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <form onSubmit={onSubmit}>
      <QuestionContainer
        autoHighlight={false}
        focusIndex={questionIndex}
        setFocusIndex={setQuestionIndex}
        questions={[
          {
            title: 'How old are you?',
            children: (
              <AgePicker age={age} setAge={setAge} hideUnderage={true} />
            ),
          },
          {
            title: 'Where are you from?',
            children: <CountryStatePicker onChange={setLocation} />,
          },
          {
            title: 'What is your Gender?',
            children: (
              <RadioList
                name='gender'
                options={genderOptions}
                typing={true}
                onChange={(option) => setGender(option)}
                selectedOption={gender}
              />
            ),
          },
          {
            title: 'Do you have children?',
            children: (
              <RadioList
                name='kids'
                options={kidsOptions}
                onChange={(option) => setKids(option)}
                selectedOption={kids}
              />
            ),
          },
        ]}
      />
      <div className='text-center my-5 pt-4'>
        <SubmitButton isSubmitting={isSubmitting}>Continue</SubmitButton>
      </div>
    </form>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateQuizStat,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoupleInviteeForm);
