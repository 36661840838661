import SubmitButton from '../../../../../SubmitButton';
import PartnerInfoForm from '../../../../forms/PartnerInfoForm';
import './UpgradeStep.css';

const Partners = ({ disabled, form, partnerLimit, onSkip }) => {
  const onSubmit = (e) => {
    e.preventDefault();
    form.submitForm();
  };

  return (
    <div className='upgrade-step'>
      <div className='body'>
        <div className='title'>Partner’s information</div>
        <PartnerInfoForm form={form} partnerLimit={partnerLimit} />
      </div>
      <div className='foot flex-wrap-reverse flex-sm-nowrap'>
        <button
          className='btn btn-light w-100'
          onClick={onSkip}
          disabled={disabled || form.isSubmitting}
        >
          Skip to my report
        </button>
        <SubmitButton
          isSubmitting={form.isSubmitting}
          onClick={onSubmit}
          disabled={disabled || form.isSubmitting}
        >
          Yes, please!
        </SubmitButton>
      </div>
    </div>
  );
};

export default Partners;
