import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getResults } from '../../../libs/api/quizStatApi';
import {
  removeQuizAccessToken,
  removeQuizSessionToken,
  setQuizCompletedCookie,
} from '../../../libs/services/quizAccessService';
import QuizLayout from '../../../components/layout/QuizLayout';
import Loader from '../../../components/quiz/loader/Loader';

const QuizRedirectPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = () => {
    getResults()
      .then(({ redirectUrl }) => {
        setQuizCompletedCookie(slug);
        removeQuizTokens();
        window.location = redirectUrl ?? '/';
      })
      .catch(({ message }) => {
        removeQuizTokens();
        navigate('/');
        toast.error(message);
      });
  };

  const removeQuizTokens = () => {
    removeQuizAccessToken(slug);
    removeQuizSessionToken();
  };

  return (
    <QuizLayout showFooter={false}>
      <Loader />
    </QuizLayout>
  );
};

export default QuizRedirectPage;
