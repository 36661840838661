import SubmitButton from '../../SubmitButton';
import ValidationError from '../../ValidationError';
import useShareReportForm from '../hooks/useShareReportForm';
import * as utils from '../../../libs/utils/form.utils';

const ShareReportForm = ({
  submitText = 'Share My Results',
  onCancelCallback = () => {},
  ...props
}: any) => {
  const { form } = useShareReportForm(props);

  const isInvalidField = (field: string) => utils.isInvalidField(form, field);

  const checkedHandler = (e: any, field: string) =>
    form.setFieldValue(field, e.target.checked);

  const onSubmit = (e: any) => {
    e.preventDefault();
    form.submitForm();
  };

  return (
    <form className='share-report-form' onSubmit={onSubmit}>
      <div className='mb-5'>
        <div className='form-check d-flex text-purple-dark'>
          <input
            id='terms'
            type='checkbox'
            className='form-check-input'
            onChange={(e) => checkedHandler(e, 'terms')}
            checked={form.values.terms}
          />
          <label className='form-check-label ms-2 fw-light' htmlFor='terms'>
            I expressly authorize the processing of sensitive/special categories
            of my personal data (concerning attachment style and/or implied
            sexual orientation), and its use through automated decision-making
            processes for the purposes of participating in the quiz.
          </label>
        </div>
        {isInvalidField('terms') && (
          <ValidationError error={form.errors.terms} />
        )}
      </div>
      <SubmitButton
        isSubmitting={form.isSubmitting}
        className='me-3 mb-3 mw-420'
      >
        {submitText}
      </SubmitButton>
      <button
        type='button'
        className='btn btn-outline-secondary mb-3'
        onClick={onCancelCallback}
      >
        Cancel
      </button>
    </form>
  );
};

export default ShareReportForm;
