import IQuizResults from '../../interfaces/IQuizResults';
import image from '../../assets/images/report/maladaptive-schema.png';
import QuizResult from '../../components/quiz/result/maladaptive-schema/QuizResult';

export class MaladaptiveSchema implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ schema, riskDomainCnt, redirectUrl }: any) {
    return {
      subtitle: 'Your Highest-Scoring Maladaptive Schema:',
      title: schema,
      description: `You have ${riskDomainCnt} other high and very high domains.`,
      bulletPoints: [
        'See your results compared to a global population.',
        'Complete breakdown of your scores from the 18 Schema Domains.',
        'More information about the Schema Domains.',
      ],
      color: '#E66C4C',
      backgroundColor: '#E66C4C',
      redirectUrl,
      image,
    };
  }
}
