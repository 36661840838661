import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getQuizStat } from '../../../../libs/redux/actions/quizStatActions';
import OpenGraph from '../../../../components/OpenGraph';
import QuizLayout from '../../../../components/layout/QuizLayout';
import Loader from '../../../../components/quiz/loader/Loader';
import CoupleInviterForm from '../../../../components/quiz/forms/CoupleInviterForm';
import CoupleInviteeForm from '../../../../components/quiz/forms/CoupleInviteeForm';
import CoupleInviterDisclaimer from '../../../../components/quiz/couple/CoupleInviterDisclaimer';
import CoupleInviteeDisclaimer from '../../../../components/quiz/couple/CoupleInviteeDisclaimer';
import useStartQuiz from './useStartQuiz';
import useOpenGraph from '../useOpenGraph';

const StartQuizPage = (props: any) => {
  const {
    isInviter,
    isLoading,
    showDisclaimer,
    setShowDisclaimer,
    quiz,
    quizStat,
    progress,
    setProgress,
    enmEnabled
  } = useStartQuiz(props);

  const navigate = useNavigate();
  const ogProps = useOpenGraph();

  const header = {
    title: quiz.name,
  };

  const navbar = {
    leftAction: {
      content: 'Cancel',
      onClick: () => navigate('/'),
    },
  };

  return (
    <>
      <OpenGraph {...ogProps} />
      <QuizLayout
        header={header}
        proggres={progress}
        navbar={navbar}
        showFooter={!isLoading}
      >
        {isLoading ? (
          <Loader />
        ) : isInviter ? (
          <>
            <CoupleInviterForm
              quiz={quiz}
              setProgress={setProgress}
              enmEnabled={enmEnabled}
            />
            <CoupleInviterDisclaimer
              isOpened={showDisclaimer}
              setIsOpened={setShowDisclaimer}
            />
          </>
        ) : (
          <>
            <CoupleInviteeForm quizStat={quizStat} />
            <CoupleInviteeDisclaimer
              isOpened={showDisclaimer}
              setIsOpened={setShowDisclaimer}
            />
          </>
        )}
      </QuizLayout>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    quiz: state.quiz,
    quizStat: state.quizStat,
  };
};

const mapDispatchToProps = {
  getQuizStat,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartQuizPage);
