import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as BackgroundSVG } from '../../../../assets/images/attachment-bg.svg';
import SendReportCard from '../../send-report-card/SendReportCard';
import UpgradeToCoupleReport from './upgrade-report/UpgradeToCoupleReport';
import './QuizResult.css';

interface Props {
  subtitle: string;
  title: string;
  description: string;
  isUnderage: boolean;
  image?: string;
  bulletPoints?: string[];
  backgroundColor?: string;
  color?: string;
  titleFontSize?: string;
  onSuccessCallback: () => void;
}

const QuizResult: React.FC<Props> = ({
  subtitle,
  title,
  description,
  isUnderage,
  image,
  bulletPoints = [],
  backgroundColor = 'var(--color-brand-main-700)',
  color = 'var(--primary-purple-dark-600)',
  onSuccessCallback,
}) => {
  const [params, setParams] = useState({});
  const [upgradeReport, setUpgradeReport] = useState(false);
  const [searchParams] = useSearchParams();

  const isUpgradable =
    !isUnderage && searchParams.get('couplesupgrade') === '1';

  const sendReportSuccessHandler = (params: any) => {
    if (isUpgradable) {
      setParams(params);
      setUpgradeReport(true);
    } else {
      onSuccessCallback();
    }
  };

  return (
    <>
      <div className='quiz-result'>
        <div className='content flex-wrap flex-lg-nowrap'>
          <div className='result-wrapper'>
            <h4 className='subtitle'>{subtitle}</h4>
            <h1 className='title' style={{ color }}>
              {title}
            </h1>
            <span className='description'>{description}</span>
          </div>
          <div className='send-report-wrapper mb-5 mb-lg-0'>
            <SendReportCard
              imageSrc={image}
              bulletPoints={bulletPoints}
              onSendReportSuccess={sendReportSuccessHandler}
            />
          </div>
        </div>
        <BackgroundSVG className='background' color={backgroundColor} />
      </div>
      {isUpgradable && (
        <UpgradeToCoupleReport
          params={params}
          isOpened={upgradeReport}
          setIsOpened={setUpgradeReport}
          onSuccess={onSuccessCallback}
        />
      )}
    </>
  );
};

export default QuizResult;
