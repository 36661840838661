import React from 'react';
import { connect } from 'react-redux';
import {
  getQuizBySlug,
  invalidateQuizState,
} from '../../../../../libs/redux/actions/quizActions';
import { upgradeQuizStat } from '../../../../../libs/redux/actions/coupleQuizStatActions';
import Modal from '../../../../modal/Modal';
import Introduction from './partials/Introduction';
import Partners from './partials/Partners';
import Situation from './partials/Situation';
import Quiz from '../../../../../interfaces/Quiz';
import useUpgradeReport from './useUpgradeReport';
import './UpgradeToCoupleReport.css';

interface Props {
  params: any;
  isOpened: boolean;
  quiz: Quiz;
  setIsOpened: (v: boolean) => void;
  getQuizBySlug: (slug: string) => any;
  invalidateQuizState: () => void;
  onSuccess: (uri?: string) => void;
}

const UpgradeToCoupleReport: React.FC<Props> = (props) => {
  const {
    disabled,
    isOpened,
    setIsOpened,
    step,
    setStep,
    onSkip,
    mode,
    setMode,
    form,
    partnerLimit,
  } = useUpgradeReport(props);

  return (
    <Modal
      id='upgrade-couple-report'
      size='lg'
      isOpened={isOpened}
      setIsOpened={setIsOpened}
      data-bs-backdrop='static'
    >
      <Modal.Body>
        {step === 1 && (
          <Introduction
            onSkip={onSkip}
            onConfirm={() => setStep(2)}
            disabled={disabled}
          />
        )}
        {step === 2 && (
          <Situation
            onSkip={onSkip}
            onConfirm={() => setStep(3)}
            option={mode}
            setOption={setMode}
            disabled={disabled}
          />
        )}
        {step === 3 && (
          <Partners
            form={form}
            onSkip={onSkip}
            partnerLimit={partnerLimit}
            disabled={disabled}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    quiz: state.quiz,
  };
};

const mapDispatchToProps = {
  getQuizBySlug,
  upgradeQuizStat,
  invalidateQuizState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpgradeToCoupleReport);
