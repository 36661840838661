import { EmotionalRegulation } from '../models/EmotionalRegulation';
import { MaladaptiveDaydreaming } from '../models/MaladaptiveDaydreaming';
import { MaladaptiveSchema } from '../models/MaladaptiveSchema';
import { RelationshipStructures } from '../models/RelationshipStructures';
import { SelfEsteem } from '../models/SelfEsteem';
import { Superpowers } from '../models/Superpowers';
import { Limerence } from '../models/Limerence';
import { RomanticPartners } from '../models/RomanticPartners';

export const MODE_REGULAR = 'single';
export const MODE_COUPLE = 'couple';
export const MODE_COMPARE = 'compare';

export const RELATIONSHIP_STRUCTURES = 'relationship-structures';
export const EMOTIONAL_REGULATION = 'emotional-regulation';
export const MALADAPTIVE_DAYDREAMING = 'maladaptive-daydreaming';
export const MALADAPTIVE_SCHEMA = 'maladaptive-schema';
export const SUPERPOWERS = 'superpowers';
export const LIMERENCE = 'limerence';
export const SELF_ESTEEM = 'self-esteem';
export const ROMANTIC_PARTNERS = 'romantic-partners';
export const COMPARISON_QUIZ = 'comparison-quiz';

// const LS_COMPLETED_ROUTE = 'completed';
const LS_REDIRECT_ROUTE = 'redirect';
const LS_DEFAULT_ROUTE = 'preliminary-result';

const quizModels: any = {
  [RELATIONSHIP_STRUCTURES]: RelationshipStructures,
  [EMOTIONAL_REGULATION]: EmotionalRegulation,
  [MALADAPTIVE_DAYDREAMING]: MaladaptiveDaydreaming,
  [MALADAPTIVE_SCHEMA]: MaladaptiveSchema,
  [SUPERPOWERS]: Superpowers,
  [LIMERENCE]: Limerence,
  [SELF_ESTEEM]: SelfEsteem,
  [ROMANTIC_PARTNERS]: RomanticPartners,
};

const quizLastStepRoutes: any = {
  [COMPARISON_QUIZ]: LS_REDIRECT_ROUTE,
};

const excludeFromCompletedCookie: string[] = [
  ROMANTIC_PARTNERS,
  // Add other quiz slugs here as needed
];

export const getQuizModel = (key: string) => quizModels[key];

export const getQuizLastStepRoute = (key: string): string => {
  const path = quizLastStepRoutes[key] || LS_DEFAULT_ROUTE;

  return `/${key}/${path}`;
};

export const canSetCompletedCookie = (slug: string): boolean => {
  return !excludeFromCompletedCookie.includes(slug);
};
