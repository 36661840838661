import ReactSelect from 'react-select';
import { components } from 'react-select';

const Select = ({ styles = {}, ...props }) => {
  return (
    <ReactSelect
      styles={{ ...defaultStyles, ...styles }}
      menuPlacement='auto'
      components={{ Option: CustomOption }}
      {...props}
    />
  );
};

const { Option } = components;

const Divider = () => (
  <div
    style={{
      height: '1px',
      backgroundColor: 'var(--color-brand-neutral-100)',
      margin: '16px 0',
    }}
  />
);

const CustomOption = (props) => {
  return (
    <>
      {props.data.divider ? (
        <Divider />
      ) : (
        <Option {...props}>{props.label}</Option>
      )}
    </>
  );
};

const defaultStyles = {
  menu: (styles) => ({
    ...styles,
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '440px',
    padding: '16px',
  }),
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      padding: '9px 16px',
      borderColor: isFocused
        ? 'var(--color-brand-main-700)'
        : 'var(--color-brand-neutral-300);',
      boxShadow: isFocused
        ? '0 0 0 0.25rem rgba(96, 76, 141, 0.25)'
        : 'var(--color-brand-neutral-900)',
      '&:hover': {
        borderColor: 'var(--color-brand-neutral-300)',
      },
      '&:active': {
        borderColor: 'var(--color-brand-main-700)',
      },
      cursor: 'pointer',
    };
  },
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    color: isSelected
      ? 'var(--color-brand-main-700)'
      : 'var(--color-brand-neutral-900)',
    ':active': {
      backgroundColor: 'var(--color-brand-main-0)',
    },
    backgroundColor:
      isSelected || isFocused ? 'var(--color-brand-main-0)' : 'initial',
    border: '1px solid transparent',
    borderRadius: '6px',
    borderColor: isSelected ? 'var(--color-brand-main-700)' : 'transparent',
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    marginBottom: '8px',
    lineHeigh: '22px',
  }),
  indicatorSeparator: () => {},
};

export default Select;
