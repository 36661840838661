interface AppEnvConfig {
  environment: string;
  apiUrl: string;
  mindOnlyUrl: string;
  wordpressUrl: string;
  shareThisUrl: string;
  sentryDns: string;
}

const defaults: AppEnvConfig = {
  environment: 'local',
  apiUrl: 'http://api.survey.local/api/v1',
  mindOnlyUrl: 'https://mindonly.com/',
  wordpressUrl: 'https://www.attachmentproject.com',
  shareThisUrl:
    'https://platform-api.sharethis.com/js/sharethis.js#property=65e740d56360a80019e2e687&product=sticky-share-buttons',
  sentryDns: '',
};

const env: AppEnvConfig = {
  environment: process.env.REACT_APP_ENV || defaults.environment,
  apiUrl: process.env.REACT_APP_API_URL || defaults.apiUrl,
  mindOnlyUrl: process.env.REACT_APP_MIND_ONLY_URL || defaults.mindOnlyUrl,
  wordpressUrl: process.env.REACT_APP_WORDPRESS_URL || defaults.wordpressUrl,
  shareThisUrl: process.env.REACT_APP_SHARE_THIS_URL || defaults.shareThisUrl,
  sentryDns: process.env.REACT_APP_SENTRY_DNS || defaults.sentryDns,
};

export default env;
