import styles from './LegacyFooter.module.css';
import footerSvg from '../../assets/images/footer-shape.svg';
import env from '../../env';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.container}`}>
        <a href={env.mindOnlyUrl} className='mx-3 me-md-auto'>
          © MindOnly Pty Ltd
        </a>
        <a href={`${env.wordpressUrl}/privacy-policy`} className='mx-3'>
          Privacy
        </a>
        <a href={`${env.wordpressUrl}/terms`} className='mx-3'>
          Terms
        </a>
      </div>
      <img src={footerSvg} className={styles.background} alt='Background' />
    </footer>
  );
};

export default Footer;
