import { Link } from 'react-router-dom';
import QuizLayout from '../../../components/layout/QuizLayout';
import SuccessMessage from '../../../components/success-message/SuccessMessage';
import env from '../../../env';
import './LimitReachedPage.css';

const LimitReachedPage = () => {
  return (
    <QuizLayout showFooter={false}>
      <div className='limit-reached'>
        <SuccessMessage>
          You reached the limit for this quiz! Try again in 30 minutes
        </SuccessMessage>
        <Link to={env.wordpressUrl} className='btn btn-secondary w-50 mt-5'>
          Home
        </Link>
      </div>
    </QuizLayout>
  );
};

export default LimitReachedPage;
