import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/limerence/QuizResult';
import image from '../../assets/images/report/limerence.png';

const SCORE_LOW = 'low';
const SCORE_LOW_MEDIAN = 'low-median';
const SCORE_MEDIAN = 'median';
const SCORE_MEDIAN_HIGH = 'median-high';
const SCORE_HIGH = 'high';

export class Limerence implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score, score_range, redirectUrl }: any) {
    const label = this.getLabelByScoreRange(score_range);

    return {
      subtitle: 'Your limerence score is:',
      title: `${score}%`,
      label:`${label}`,
      description: 'Your responses to the items in this quiz suggest that you are not (or have not) experiencing many of the strong or intense emotions associated with limerence.',
      bulletPoints: [
        'Understand the science behind limerence.',
        'Complete breakdown of your score.',
        'More information about steps you can take.',
      ],
      color: '#7cd9dd',
      backgroundColor: '#2da1a4',
      redirectUrl,
      image,
    };
  }

  getLabelByScoreRange(range: string) {
    const scoreTitles: any = {
      [SCORE_LOW]: 'You are not a limerent.',
      [SCORE_LOW_MEDIAN]: 'You are unlikely to be a limerent.',
      [SCORE_MEDIAN]: 'You might be a limerent.',
      [SCORE_MEDIAN_HIGH]: 'You are probably a limerent.',
      [SCORE_HIGH]: 'You are a limerent.',
    };

    return scoreTitles[range] ?? 'N/A';
  }
}
