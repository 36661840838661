import * as quizStatApi from '../../api/compareQuizStatApi';
import { createQuizStatSuccess } from './quizStatActions';

export function createQuizStat(params = {}) {
  return function (dispatch) {
    return quizStatApi.createQuizStat(params).then((res) => {
      const { quiz_stat } = res;

      dispatch(createQuizStatSuccess(quiz_stat));
      return res;
    });
  };
}
