import { useEffect, useState } from 'react';
import Modal from '../modal/Modal';
import './ParentalConsent.css';

const ParentalConsent = ({ isOpened, setIsOpened, onConfirm }: any) => {
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    setConsentGiven(false);
  }, [isOpened]);

  const consentHandler = (e: any) => setConsentGiven(e.target.checked);

  return (
    <Modal
      id='parental-consent'
      isOpened={isOpened}
      setIsOpened={setIsOpened}
    >
      <Modal.Header>Parental consent</Modal.Header>
      <Modal.Body>
        By clicking "Agree", you confirm that:
        <ul className='my-3'>
          <li>You are above the legal age of consent in your country.</li>
          <li>
            You have your parent's or guardian's consent to participate in this
            quiz.
          </li>
          <li>
            You and your parent/guardian agree to our Terms and Conditions.
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer className='modal-footer pt-0 justify-content-between'>
        <div className='form-check d-flex align-items-center'>
          <input
            id='parental-agree'
            type='checkbox'
            className='form-check-input'
            value='on'
            onChange={consentHandler}
            checked={consentGiven}
          />
          <label className='form-check-label ms-3' htmlFor='parental-agree'>
            I agree and have parental consent to continue.
          </label>
        </div>
        <Modal.ActionButton
          className='btn btn-secondary btn-agree'
          onClick={onConfirm}
          disabled={!consentGiven}
        >
          Continue
        </Modal.ActionButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ParentalConsent;
