import { useEffect, useState } from 'react';
import { getGeolocation } from '../libs/services/locationService';
import {
  emphasizedCountriesList,
  otherCountriesList,
  stateList,
} from '../libs/helpers/countryHelper';
import Select from './Select';

interface ChangeResponse {
  isGeolocated: boolean;
  isStateAvailable: boolean;
  country: string | null;
  state: string | null;
}

interface Props {
  shouldGeolocate?: boolean;
  onChange: (params: object) => ChangeResponse;
}

const CountryStatePicker: React.FC<Props> = ({
  onChange,
  shouldGeolocate = true,
}) => {
  const [country, setCountry]: any = useState(null);
  const [state, setState]: any = useState(null);

  const getStateOptions = (countrCode: string): object[] => {
    const countryStateList = stateList?.[countrCode] ?? {};

    return Object.entries(countryStateList).map(([value, label]) => ({
      label,
      value,
    }));
  };

  const isStateAvailable = (countrCode: string) => {
    const statesObj = stateList?.[countrCode] ?? {};

    return Object.keys(statesObj).length > 0;
  };

  useEffect(() => {
    shouldGeolocate && fetchGeolocation();
  }, []);

  const fetchGeolocation = () => {
    getGeolocation().then((response: any) => {
      const { country = null, state = null } = response || {};

      const countryOption = countryOptions.find(
        ({ value }: any) => value === country,
      );

      const countryValue = getOptionValue(countryOption);

      const stateOption =
        getStateOptions(countryValue).find(
          ({ value }: any) => value === state,
        ) ?? null;

      const stateValue = getOptionValue(stateOption);

      setCountry(countryOption);
      setState(stateOption);
      onChange({
        isGeolocated: true,
        country: countryValue,
        state: stateValue,
        isStateAvailable: isStateAvailable(countryValue),
      });
    });
  };

  const onCountryChange = (country: any) => {
    const countryValue = getOptionValue(country);

    setCountry(country);
    setState(null);
    onChange({
      isGeolocated: false,
      country: countryValue,
      state: null,
      isStateAvailable: isStateAvailable(countryValue),
    });
  };

  const onStateChange = (state: any) => {
    const stateValue = getOptionValue(state);
    const countryValue = getOptionValue(country);

    setState(state);
    onChange({
      isGeolocated: false,
      country: countryValue,
      state: stateValue,
      isStateAvailable: isStateAvailable(countryValue),
    });
  };

  const getOptionValue = (option: any) => option?.value ?? null;

  const stateOptions = getStateOptions(country?.value);

  return (
    <>
      <Select
        options={countryOptions}
        value={country}
        onChange={onCountryChange}
        menuPlacement='bottom'
        placeholder='Select country'
        isSearchable
        isClearable
      />
      {stateOptions.length > 1 && (
        <Select
          options={stateOptions}
          value={state}
          onChange={onStateChange}
          menuPlacement='bottom'
          placeholder='Select state'
          className='mt-3'
          isSearchable
          isClearable
        />
      )}
    </>
  );
};

export default CountryStatePicker;

const emphasizedCountriesOptions = Object.entries(emphasizedCountriesList).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

const otherCountriesOptions = Object.entries(otherCountriesList).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

const countryOptions = [
  ...emphasizedCountriesOptions,
  ...[{ divider: true }],
  ...otherCountriesOptions,
];
