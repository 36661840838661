import { Link, useParams, useSearchParams } from 'react-router-dom';
import QuizLayout from '../../components/layout/QuizLayout';
import SuccessMessage from '../../components/success-message/SuccessMessage';
import env from '../../env';
import './SuccessMessage.css';

const SuccessMessagePage = () => {
  const { message } = useParams();
  const [searchParams] = useSearchParams();

  const buttonText = searchParams.get('buttonText') ?? 'Home';
  const redirectUrl = searchParams.get('redirectUrl') ?? env.wordpressUrl;

  return (
    <QuizLayout showFooter={false}>
      <div className='success-message-page'>
        <SuccessMessage>{message}</SuccessMessage>
        <Link to={redirectUrl} className='btn btn-secondary w-25 mt-5'>
          {buttonText}
        </Link>
      </div>
    </QuizLayout>
  );
};

export default SuccessMessagePage;
