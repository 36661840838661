import { connect } from 'react-redux';
import { createQuizStat } from '../../../libs/redux/actions/coupleQuizStatActions';
import QuestionContainer from '../../question-container/QuestionContainer';
import ValidationError from '../../ValidationError';
import AgePicker from '../../AgePicker';
import CountryStatePicker from '../../CountryStatePicker';
import RadioList from '../../radio-list/RadioList';
import SubmitButton from '../../SubmitButton';
import CoupleModePicker from '../couple/CoupleModePicker';
import useCoupleInviterForm from '../hooks/useCoupleInviterForm';
import PartnerInfoForm from './PartnerInfoForm';
import * as utils from '../../../libs/utils/form.utils';

const CoupleInviterForm = (props: any) => {
  const {
    form,
    type,
    setType,
    age,
    setAge,
    gender,
    setGender,
    kids,
    setKids,
    setLocation,
    genderOptions,
    kidsOptions,
    getPartnerLimit,
    questionIndex,
    setQuestionIndex,
    shouldFocus,
    isExclusive,
    enmEnabled,
  } = useCoupleInviterForm(props);

  const singlePartner = isExclusive();

  const onCountryStateChange = (params: any) => setLocation(params);

  const getPartnersInfoDescr = () => {
    return `By providing your ${
      singlePartner ? "partner's" : 'partner(s)'
    } details, you confirm that you have their consent. They will receive an invitation to take the partner test, which they can accept or decline.
    <br/><br/>If multiple partners have the same first name, please add an extra character to differentiate them.`;
  };

  const getFieldProps = (field: string) => utils.getFieldProps(form, field);

  const isInvalidField = (field: string) => utils.isInvalidField(form, field);

  const onSubmit = (e: any) => {
    e.preventDefault();
    form.submitForm();
  };

  return (
    <form onSubmit={onSubmit}>
      <QuestionContainer
        autoHighlight={false}
        focusIndex={shouldFocus ? questionIndex : -1}
        setFocusIndex={setQuestionIndex}
        totalCount={7}
        questions={[
          {
            title: 'What is your current situation?',
            children: (
              <CoupleModePicker
                option={type}
                setOption={setType}
                className='flex-wrap flex-sm-nowrap'
                enmEnabled={enmEnabled}
              />
            ),
          },
          {
            title: 'Your information',
            children: (
              <>
                <div className='mb-3'>
                  <input
                    autoComplete='none'
                    placeholder='Your name'
                    {...getFieldProps('name')}
                  />
                  {isInvalidField('name') && (
                    <ValidationError error={form.errors.name} />
                  )}
                </div>
                <div className='mb-3'>
                  <input
                    autoComplete='none'
                    placeholder='Your email'
                    {...getFieldProps('email')}
                  />
                  {isInvalidField('email') && (
                    <ValidationError error={form.errors.email} />
                  )}
                </div>
              </>
            ),
          },
          {
            title: 'How old are you?',
            children: (
              <AgePicker age={age} setAge={setAge} hideUnderage={true} />
            ),
          },
          {
            title: 'Where are you from?',
            children: <CountryStatePicker onChange={onCountryStateChange} />,
          },
          {
            title: 'What is your Gender?',
            children: (
              <RadioList
                name='gender'
                options={genderOptions}
                typing={true}
                onChange={(option) => setGender(option)}
                selectedOption={gender}
              />
            ),
          },
          {
            title: 'Do you have children?',
            children: (
              <RadioList
                name='kids'
                options={kidsOptions}
                onChange={(option) => setKids(option)}
                selectedOption={kids}
              />
            ),
          },
          {
            title: 'Partner’s information',
            description: getPartnersInfoDescr(),
            children: (
              <PartnerInfoForm form={form} partnerLimit={getPartnerLimit()} />
            ),
          },
        ]}
      />
      <div className='text-center my-5 pt-4'>
        <SubmitButton isSubmitting={form.isSubmitting}>Continue</SubmitButton>
      </div>
    </form>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createQuizStat,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoupleInviterForm);
