import { useParams } from 'react-router-dom';
import * as quizHelper from '../../../libs/helpers/quizHelper';

interface OpenGraphConfig {
  title: string;
  description?: string;
}

const useOpenGraph = (): OpenGraphConfig => {
  const { slug = '' } = useParams();

  const defaultConfig: OpenGraphConfig = {
    title: 'The Attachment Project | Set Up Profile',
  };

  const config: { [key: string]: OpenGraphConfig } = {
    [quizHelper.RELATIONSHIP_STRUCTURES]: {
      title: 'The Attachment Project | Relationship Structures',
    },
    [quizHelper.EMOTIONAL_REGULATION]: {
      title: 'The Attachment Project | Emotional Regulation',
      description:
        'Take the free Emotional Regulation Test. Do you have Emotional Dysregulation?',
    },
    [quizHelper.MALADAPTIVE_DAYDREAMING]: {
      title: 'The Attachment Project | Maladaptive Daydreaming',
    },
    [quizHelper.MALADAPTIVE_SCHEMA]: {
      title: 'The Attachment Project | Maladaptive Schema',
      description:
        'Take the free Maladaptive Schema Test to discover the results of your 18 Schema’s. Do you have the most common Maladaptive Schema’s?',
    },
    [quizHelper.SELF_ESTEEM]: {
      title: 'The Attachment Project | Self Esteem',
    },
    [quizHelper.SUPERPOWERS]: {
      title: 'The Attachment Project | Superpowers',
    },
    [quizHelper.LIMERENCE]: {
      title: 'The Attachment Project | Limerence',
    },
    [quizHelper.ROMANTIC_PARTNERS]: {
      title: 'The Attachment Project | Romantic Partners',
    },
    [quizHelper.COMPARISON_QUIZ]: {
      title: 'The Attachment Project | Comparison Quiz',
    },
  };

  return config[slug] || defaultConfig;
};

export default useOpenGraph;
