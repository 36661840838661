import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { options, defaultLimits } from '../../../couple/CoupleModePicker';
import { ROMANTIC_PARTNERS } from '../../../../../libs/helpers/quizHelper';
import * as Yup from 'yup';

const useUpgradeReport = ({
  isOpened,
  setIsOpened,
  getQuizBySlug,
  upgradeQuizStat,
  invalidateQuizState,
  quiz,
  params,
  onSuccess,
}: any) => {
  const [disabled, setDisabled] = useState(false);
  const [step, setStep] = useState(0);
  const [mode, setMode] = useState(options[0]);

  const { name = '', email = '' } = params;

  const partnerLimit = defaultLimits[mode?.value] || 0;

  const uniqueEmailValidator = function (this: any, value: string) {
    const partnerEmails: any =
      this.options.context?.partners?.map((partner: any) => partner.email) ||
      [];
    const allEmails = [...partnerEmails, email];

    const isUnique = allEmails.filter((email) => email === value).length === 1;
    return isUnique;
  };

  const validationSchema: any = Yup.object().shape({
    quiz_id: Yup.number().required(),
    type: Yup.string().required(),
    email: Yup.string().email().required(),
    name: Yup.string().required(),
    partners: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name is required.'),
        email: Yup.string()
          .email('Email format is invalid.')
          .required('Email is required.')
          .test('uniqueEmail', 'Emails must be unique.', uniqueEmailValidator),
      }),
    ),
  });

  const onSubmit = (values: any, { setSubmitting }: any) => {
    upgradeQuizStat(values)
      .then(({ token }: any) => {
        const navigateTo = getRedirectUri(token);
        onSuccess({ navigateTo });
      })
      .catch(({ message }: any) => {
        toast.error(message);
        setSubmitting(false);
      });
  };

  const form = useFormik({
    initialValues: {
      quiz_id: '',
      type: '',
      email: email,
      name: name,
      partners: [],
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  const fetchQuiz = () => {
    getQuizBySlug(ROMANTIC_PARTNERS)
      .then(() => setStep(1))
      .catch(() => toast.error('Something went wrong !'));
  };

  const onSkip = () => {
    setDisabled(true);
    onSuccess();
  };

  const getRedirectUri = (token: string) => {
    const quizSlug = quiz.slug;
    const surveySlug = quiz.quiz_surveys[0]?.slug;
    return `/${quizSlug}/${surveySlug}?token=${token}`;
  };

  useEffect(() => {
    fetchQuiz();

    return () => {
      invalidateQuizState();
    };
  }, []);

  useEffect(() => {
    form.setFieldValue('quiz_id', quiz?.id ?? '');
  }, [quiz]);

  useEffect(() => {
    form.setFieldValue('email', email);
  }, [email]);

  useEffect(() => {
    form.setFieldValue('name', name);
  }, [name]);

  useEffect(() => {
    form.setFieldValue('type', mode.value ?? '');
  }, [mode]);

  return {
    disabled,
    isOpened,
    setIsOpened,
    step,
    setStep,
    onSkip,
    mode,
    setMode,
    form,
    partnerLimit,
  };
};

export default useUpgradeReport;
