import * as types from './types';
import * as answerApi from '../../api/userAnswerApi';

export function createOrUpdateAnswerSuccess(payload) {
  return { type: types.CREATE_OR_UPDATE_ANSWER_SUCCESS, payload };
}

export function createOrUpdateAnswerFailed(payload) {
  return { type: types.CREATE_OR_UPDATE_ANSWER_FAILED, payload };
}

export function createOrUpdateAnswer(params = {}) {
  return function (dispatch) {
    return answerApi
      .createOrUpdateAnswer(params)
      .catch((e) => {
        dispatch(createOrUpdateAnswerFailed(params));
        throw e;
      });
  };
}
