import coverImage from '../../../../../../assets/images/upgrade-romantic-parners.png';
import './UpgradeStep.css';

const Introduction = ({ onSkip, onConfirm, disabled }) => {
  return (
    <div className='upgrade-step'>
      <div className='head'>
        <img className='cover' src={coverImage} alt='Cover' />
        <div className='content d-none d-lg-block'>
          <div className='title'>Takes 3 Minutes...</div>
          <ul>
            <li>
              Get a separate 10-page personalized report for you and your
              partner
            </li>
            <li>Find out your attachment style compatibility</li>
            <li>Get results on both of your emotional regulation scores</li>
          </ul>
        </div>
      </div>
      <div className='body'>
        <div className='title'>Upgrade to the Couple’s Attachment Quiz!</div>
        <p>
          Curious to find out your relationship’s attachment styles combination?
          Discover how well your and your partner’s styles match and how well
          each of you regulates their emotions. Would you like to invite your
          partner to take this quiz with you?
        </p>
        <div className='disclaimer'>
          <h6>DISCLAIMER</h6>
          <p>
            You and the invitees are above the age of legal consent in your
            country, confirming eligibility to participate in this quiz. The
            quiz may allow users to infer aspects of sexuality based on the
            demographic data provided, which could be reflected in the report
            generated.
          </p>
        </div>
      </div>
      <div className='foot flex-wrap-reverse flex-sm-nowrap'>
        <button
          className='btn btn-light w-100'
          onClick={onSkip}
          disabled={disabled}
        >
          Skip to my report
        </button>
        <button
          className='btn btn-secondary w-100'
          onClick={onConfirm}
          disabled={disabled}
        >
          Yes, please!
        </button>
      </div>
    </div>
  );
};

export default Introduction;
