export const GENDER_MALE = 'male';
export const GENDER_FEMALE = 'female';
export const GENDER_NON_BINARY = 'non-binary';
export const GENDER_NOT_TO_SAY = 'not-to-say';

export const genderList: object = {
  [GENDER_MALE]: 'Man',
  [GENDER_FEMALE]: 'Woman',
  [GENDER_NON_BINARY]: 'Non-binary',
  [GENDER_NOT_TO_SAY]: 'I prefer not to say',
};
