import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/relationship-structures/QuizResult';
import secureMaleImg from '../../assets/images/report/secure/male.png';
import secureFemaleImg from '../../assets/images/report/secure/female.png';
import secureDefaultImg from '../../assets/images/report/secure/default.png';
import disorganizedMaleImg from '../../assets/images/report/disorganized/male.png';
import disorganizedFemaleImg from '../../assets/images/report/disorganized/female.png';
import disorganizedDefaultImg from '../../assets/images/report/disorganized/default.png';
import dismissiveMaleImg from '../../assets/images/report/dismissive/male.png';
import dismissiveFemaleImg from '../../assets/images/report/dismissive/female.png';
import dismissiveDefaultImg from '../../assets/images/report/dismissive/default.png';
import anxiousMaleImg from '../../assets/images/report/anxious/male.png';
import anxiousFemaleImg from '../../assets/images/report/anxious/female.png';
import anxiousDefaultImg from '../../assets/images/report/anxious/default.png';
import { GENDER_FEMALE, GENDER_MALE } from '../helpers/genderHelper';

const DEFAULT = 'default';

const ATTACHMENT_STYLE_SECURE = 'secure';
const ATTACHMENT_STYLE_DISMISSING = 'dismissing';
const ATTACHMENT_STYLE_ANXIOUS = 'anxious';
const ATTACHMENT_STYLE_DISORGANIZED = 'disorganized';

export class RelationshipStructures implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps(props: any) {
    return Object.assign(defaultConfig, getConfigBasedOnResults(props));
  }
}

const defaultConfig = {
  subtitle: 'Your General Attachment Style is:',
  description:
    'Learn more about your attachment style dimensions in other types of relationships.',
  bulletPoints: [
    'See your attachment style with caregivers, romantic relationships, and your general style.',
    'Over 10 pages of information about your style.',
  ],
};

const getConfigBasedOnResults = ({
  attachment = null,
  gender = null,
  ...props
}: any) => {
  if (!attachment) return {};

  const baseConfig: any = {
    [ATTACHMENT_STYLE_SECURE]: {
      title: 'Secure',
      color: '#055C5C',
      backgroundColor: '#0D7C7B',
    },
    [ATTACHMENT_STYLE_DISORGANIZED]: {
      title: 'Fearful-Avoidant / Disorganized',
      color: '#AE1D32',
      backgroundColor: '#CE2638',
    },
    [ATTACHMENT_STYLE_DISMISSING]: {
      title: 'Avoidant / Dismissive',
      color: '#336EA7',
      backgroundColor: '#5099E1',
    },
    [ATTACHMENT_STYLE_ANXIOUS]: {
      title: 'Anxious / Preoccupied',
      color: '#CA6C0A',
      backgroundColor: '#F29C43',
    },
  };

  const imageConfig: any = {
    [ATTACHMENT_STYLE_SECURE]: {
      [GENDER_FEMALE]: secureFemaleImg,
      [GENDER_MALE]: secureMaleImg,
      [DEFAULT]: secureDefaultImg,
    },
    [ATTACHMENT_STYLE_DISORGANIZED]: {
      [GENDER_FEMALE]: disorganizedFemaleImg,
      [GENDER_MALE]: disorganizedMaleImg,
      [DEFAULT]: disorganizedDefaultImg,
    },
    [ATTACHMENT_STYLE_DISMISSING]: {
      [GENDER_FEMALE]: dismissiveFemaleImg,
      [GENDER_MALE]: dismissiveMaleImg,
      [DEFAULT]: dismissiveDefaultImg,
    },
    [ATTACHMENT_STYLE_ANXIOUS]: {
      [GENDER_FEMALE]: anxiousFemaleImg,
      [GENDER_MALE]: anxiousMaleImg,
      [DEFAULT]: anxiousDefaultImg,
    },
  };

  return {
    ...props,
    ...baseConfig[attachment],
    image: imageConfig[attachment][gender] || imageConfig[attachment][DEFAULT],
  };
};
