import EmotionalDysregulation from '../../components/quiz/survey/intro/EmotionalDysregulation';
import SelfEsteem from '../../components/quiz/survey/intro/SelfEsteem';

const EMOTIONAL_DYSREGULATION = 'ders-16';
const SELF_ESTEEM = 'self-esteem';

const components: any = {
  [EMOTIONAL_DYSREGULATION]: EmotionalDysregulation,
  [SELF_ESTEEM]: SelfEsteem,
};

export const getComponentByKey = (key: string): any => {
  return components[key] ?? null;
};
