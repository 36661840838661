import * as quizStatApi from '../../api/coupleQuizStatApi';
import { createQuizStatSuccess, updateQuizStatSuccess } from './quizStatActions';

export function createQuizStat(params = {}) {
  return function (dispatch) {
    return quizStatApi.createQuizStat(params).then((res) => {
      const { quiz_stat } = res;

      dispatch(createQuizStatSuccess(quiz_stat));
      return res;
    });
  };
}

export function updateQuizStat(params = {}) {
  return function (dispatch) {
    return quizStatApi.updateQuizStat(params).then((res) => {
      const { quiz_stat } = res;

      dispatch(updateQuizStatSuccess(quiz_stat));
      return res;
    });
  };
}


export function upgradeQuizStat(params = {}) {
  return function (dispatch) {
    return quizStatApi.upgradeQuizStat(params).then((res) => {
      const { quiz_stat } = res;

      dispatch(createQuizStatSuccess(quiz_stat));
      return res;
    });
  };
}
