import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/superpowers/QuizResult';
import image from '../../assets/images/report/superpowers.png';

const SCORE_LOW = 'low';
const SCORE_MID = 'mid';
const SCORE_HIGH = 'high';

export class Superpowers implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score, score_range, redirectUrl }: any) {
    const label = this.getLabelByScoreRange(score_range);

    return {
      subtitle: 'Your Attachment Superpowers Score:',
      title: `${score}%`,
      description: `This means that when it comes to relationships, you're ${label}. Find out more in the report!`,
      bulletPoints: [
        'Free 6 Page Report with your results.',
        'Get a complete breakdown of your superpowers: Safety Net, Third Eye, Calming Force, Love Rays & Star Support.',
        'Understand the Science behind the Superpowers.',
      ],
      color: '#7cd9dd',
      backgroundColor: '#2da1a4',
      redirectUrl,
      image,
    };
  }

  getLabelByScoreRange(range: string) {
    const scoreTitles: any = {
      [SCORE_LOW]: 'a Novice',
      [SCORE_MID]: 'Just shy of a Superhero',
      [SCORE_HIGH]: 'a Superhero',
    };

    return scoreTitles[range] ?? 'N/A';
  }
}
