export const AGE_UNDER_18 = 17;
export const AGE_18_24 = 24;
export const AGE_25_30 = 30;
export const AGE_31_40 = 40;
export const AGE_41_50 = 50;
export const AGE_ABOVE_50 = 100;

export const GENDER_NOT_TO_SAY = 'not-to-say';

export const ageList: object = {
  [AGE_UNDER_18]: 'Under 18',
  [AGE_18_24]: '18-24',
  [AGE_25_30]: '25-30',
  [AGE_31_40]: '31-40',
  [AGE_41_50]: '41-50',
  [AGE_ABOVE_50]: 'Above 50',
};
