import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getResults } from '../../../libs/api/quizStatApi';
import {
  canSetCompletedCookie,
  getQuizModel,
} from '../../../libs/helpers/quizHelper';
import {
  removeQuizAccessToken,
  removeQuizSessionToken,
  setQuizCompletedCookie,
  getQuizSessionToken,
} from '../../../libs/services/quizAccessService';
import { RelationshipStructures } from '../../../libs/models/RelationshipStructures';
import PageNotFound from '../../page-not-found/PageNotFound';
import useGtag from '../../../libs/hooks/useGtag';

const useResultPage = () => {
  const [isLoading, setIsLoading]: [boolean, any] = useState(true);
  const [results, setResults]: [any, any] = useState({});

  const { sendEvent } = useGtag();
  const { slug = '' } = useParams();
  const navigate = useNavigate();

  const quizModel = getQuizModel(slug);

  useEffect(() => {
    getResults()
      .then((payload) => {
        setResults(payload);
        setIsLoading(false);
      })
      .catch(({ code }) => {
        removeQuizTokens();

        code === 403 ? navigate(`/${slug}`) : navigate('/page-not-found');
      });
  }, []);

  useEffect(() => {
    const { attachment = null }: any = results;

    if (
      quizModel === RelationshipStructures &&
      !isQuizCompletedEventSent() &&
      attachment !== null
    ) {
      sendQuizCompletedEvent(attachment);
    }
  }, [results, quizModel]);

  const removeQuizTokens = () => {
    removeQuizAccessToken(slug);
    removeQuizSessionToken();
  };

  const onSuccessCallback = (props: any = {}) => {
    removeQuizTokens();

    canSetCompletedCookie(slug) && setQuizCompletedCookie(slug);

    if (props?.navigateTo) {
      navigate(props.navigateTo);
    } else {
      window.location.href = results.redirectUrl ?? '/';
    }
  };

  const sendQuizCompletedEvent = (attachment: string): void => {
    sendEvent('attachment-quiz-complete', {
      event_category: 'quiz-completion',
      event_label: `${attachment}-quiz-result`,
    });

    localStorage.setItem(`quiz-completed:${getQuizSessionToken()}`, 'true');
  };

  const isQuizCompletedEventSent = (): boolean =>
    localStorage.getItem(`quiz-completed:${getQuizSessionToken()}`)
      ? true
      : false;

  const renderQuizResult = () => {
    if (quizModel !== undefined) {
      const modelInstance = new quizModel();
      const modelProps = modelInstance.getResultPageProps(results);
      const ResultComponent = modelInstance.getResultComponent();

      return (
        <ResultComponent
          onSuccessCallback={onSuccessCallback}
          {...modelProps}
        />
      );
    }

    return <PageNotFound />;
  };

  return {
    isLoading,
    renderQuizResult,
  };
};

export default useResultPage;
