import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/self-esteem/QuizResult';
import image from '../../assets/images/report/self-esteem.png';

const SCORE_LOW = 'low';
const SCORE_MID = 'mid';
const SCORE_HIGH = 'high';

export class SelfEsteem implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score, score_range, redirectUrl }: any) {
    const levelLabel = this.getLevelLabelByRange(score_range);
    return {
      subtitle: 'Your Self-Esteem Score:',
      title: `${score}%`,
      description: `Your score suggests ${levelLabel} levels of self-esteem. Find out more about the complete breakdown in your report.`,
      bulletPoints: [
        'See your results for Self-Esteem and deep dive into your domain scores.',
        'Complete breakdown of your scores.',
      ],
      color: '#917DBF',
      backgroundColor: '#917DBF',
      image,
      redirectUrl,
    };
  }

  getLevelLabelByRange(range: string) {
    const scoreRanges: any = {
      [SCORE_LOW]: 'low',
      [SCORE_MID]: 'middling',
      [SCORE_HIGH]: 'high',
    };

    return scoreRanges[range] ?? 'N/A';
  }
}
