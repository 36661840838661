import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setQuizSessionToken } from '../../../../libs/services/quizAccessService';
import QuizStat from '../../../../interfaces/QuizStat';
import Quiz from '../../../../interfaces/Quiz';

interface Props {
  isInviter: boolean;
  quiz: Quiz;
  quizStat: QuizStat;
  getQuizStat: () => Promise<any>;
}

interface StartQuizResult {
  isInviter: boolean;
  isLoading: boolean;
  showDisclaimer: boolean;
  quiz: Quiz;
  quizStat: QuizStat | null;
  progress: any;
  enmEnabled: any;
  setShowDisclaimer: (x: any) => Function;
  setProgress: (x: any) => Function;
}

const useStartQuiz = ({
  isInviter = true,
  quiz,
  quizStat,
  getQuizStat,
}: Props): StartQuizResult => {
  const [isLoading, setIsLoading]: [boolean, any] = useState(true);
  const [showDisclaimer, setShowDisclaimer]: [boolean, any] = useState(true);
  const [progress, setProgress]: [any, any] = useState({
    step: 1,
    totalSteps: 0,
    title: 'Set-up your profile',
    percentage: 0,
  });
  const [searchParams]: [URLSearchParams, any] = useSearchParams();

  const { slug } = useParams();
  const navigate = useNavigate();
  const token = searchParams.get('hash');

  const enmEnabled = searchParams.get('enm') === '1';

  const totalSurveys = isInviter
    ? quiz?.quiz_surveys?.length
    : quizStat?.survey_stats?.length;

  useEffect(() => {
    isInviter ? setIsLoading(false) : fetchQuizStat();
  }, [slug]);

  useEffect(() => {
    setProgress((prev: any) => ({ ...prev, totalSteps: totalSurveys + 1 }));
  }, [totalSurveys]);

  const fetchQuizStat = () => {
    setQuizSessionToken(token);
    setIsLoading(true);
    getQuizStat()
      .then(() => setIsLoading(false))
      .catch(() => navigate('/'));
  };

  return {
    isInviter,
    isLoading,
    showDisclaimer,
    setShowDisclaimer,
    quiz,
    quizStat,
    progress,
    setProgress,
    enmEnabled,
  };
};

export default useStartQuiz;
