import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoImage } from '../../assets/images/logo.svg';
import env from '../../env';
import './Header.css';

interface Props {
  title?: string;
}

const Header: React.FC<Props> = ({ title }) => {
  return (
    <header className='header'>
      <div className='container d-flex align-items-center justify-content-center justify-content-md-start fw-bolder'>
        <Link to={env.wordpressUrl}>
          <LogoImage />
        </Link>
        {title && <h1 className='title'>{title}</h1>}
      </div>
    </header>
  );
};

export default Header;
