import { ReactComponent as HeartIcon } from '../assets/icons/heart-framed.svg';
import { ReactComponent as HeartsIcon } from '../assets/icons/hearts-framed.svg';

import TilePicker from './tile-picker/TilePicker';

const RelationshipStatusPicker = ({
  options = defaultOptions,
  ...props
}: any) => {
  return <TilePicker options={options} {...props} />;
};

export const defaultOptions = [
  {
    icon: <HeartIcon />,
    value: 'single',
    label: 'I am single',
  },
  {
    icon: <HeartsIcon />,
    value: 'relationship',
    label: 'I am in a relationship',
  },
];

export default RelationshipStatusPicker;
