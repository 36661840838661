import Footer from '../footer/Footer';
import Header from '../header/Header';
import './MainLayout.css';

interface Props {
  children: any;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className='main-layout'>
      <Header />
      <div className='content'>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
