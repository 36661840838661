import { handleError, handleResponse } from './utils';
import axiosAuthInstance from './axios/authInstance';
import axiosGuestInstance from './axios/guestInstance';

const baseUrl = '/couple-quiz-stat';

export function createQuizStat(params) {
  return axiosGuestInstance
    .post(baseUrl, params)
    .then(handleResponse)
    .catch(handleError);
}

export function updateQuizStat(params) {
  return axiosAuthInstance
    .put(baseUrl, params)
    .then(handleResponse)
    .catch(handleError);
}

export function upgradeQuizStat(params) {
  return axiosAuthInstance
    .post(`${baseUrl}/upgrade`, params)
    .then(handleResponse)
    .catch(handleError);
}

export function shareReport(params) {
  return axiosAuthInstance
    .put(`${baseUrl}/share`, params)
    .then(handleResponse)
    .catch(handleError);
}
