import { ReactComponent as HeartsIcon } from '../../../assets/icons/hearts-framed.svg';
import { ReactComponent as CircleHeartsIcon } from '../../../assets/icons/circle-hearts.svg';
import { ReactComponent as InfinityHeartIcon } from '../../../assets/icons/infinity-heart.svg';
import TilePicker from '../../tile-picker/TilePicker';

export const TYPE_ONE_TO_ONE = '1:1';
export const TYPE_ONE_TO_N = '1:n';
export const TYPE_N_TO_M = 'n:m';

const CoupleModePicker = ({ enmEnabled = true, ...props }: any) => {
  const filteredOptions = enmEnabled
    ? options
    : options.filter((option) => option.value !== TYPE_N_TO_M);

  return <TilePicker options={filteredOptions} {...props} />;
};

export const options = [
  {
    icon: <HeartsIcon />,
    label: 'Relationship with One Person',
    value: TYPE_ONE_TO_ONE,
  },
  {
    icon: <CircleHeartsIcon />,
    label: 'Multiple Separate Relationships or Dating Situations',
    value: TYPE_ONE_TO_N,
  },
  {
    icon: <InfinityHeartIcon />,
    label: 'One Relationship with Multiple People',
    value: TYPE_N_TO_M,
  },
];

export const defaultLimits: any = {
  [TYPE_ONE_TO_ONE]: 1,
  [TYPE_ONE_TO_N]: 6,
  [TYPE_N_TO_M]: 5,
};

export default CoupleModePicker;
