import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/romantic-partners/QuizResult';

const NAME_CHAR_LIMIT = 10;
const MODE_ONE_TO_ONE = '1:1';
const MODE_ONE_TO_N = '1:n';
const MODE_N_TO_M = 'n:m';

export class RomanticPartners implements IQuizResults {
  defaultConfig = {
    title: 'Thank you for completing the Romantic Partner Quiz.',
  };

  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps(props: any) {
    return {
      ...this.defaultConfig,
      ...this.getConfigBasedOnResults(props),
    };
  }

  getConfigBasedOnResults = ({ inviter, mode, results, ...props }: any) => {
    const partnerNames = results.map((result: any) =>
      result.partner.length > NAME_CHAR_LIMIT
        ? result.partner.substring(0, NAME_CHAR_LIMIT) + '...'
        : result.partner,
    );

    return {
      ...props,
      description: this.getDescription(inviter, mode, partnerNames),
      resultHeader: this.getResultHeader(inviter, mode, partnerNames),
      submitText: this.getSubmitText(inviter, mode, partnerNames),
      results,
    };
  };

  getDescription = (
    inviter: boolean,
    mode: string,
    partnerNames: any,
  ): string => {
    const description = inviter
      ? inviterDescConfig[mode]
      : inviteeDescConfig[mode];

    return description.replace(
      ':partners',
      this.getPartnersString(partnerNames),
    );
  };

  getSubmitText = (inviter: boolean, mode: string, partnerNames: any) => {
    const map = inviter ? inviterSumbitTxtMap[mode] : inviteeSumbitTxtMap[mode];

    return map.replace(':partners', this.getPartnersString(partnerNames));
  };

  getResultHeader = (inviter: boolean, mode: string, partnerNames: any) => {
    const map = inviter
      ? inviterResultHeadMap[mode]
      : inviteeResultHeadMap[mode];

    return map.replace(':partners', this.getPartnersString(partnerNames));
  };

  getPartnersString(partnerNames: string[]): string {
    if (partnerNames.length > 1) {
      const lastPartner = partnerNames.pop();
      return `${partnerNames.join(', ')} and ${lastPartner}`;
    }

    return partnerNames[0];
  }
}

const inviterDescConfig: Record<string, string> = {
  [MODE_ONE_TO_ONE]: `Once you press send, we will invite :partners to complete the quiz. As soon as they finish, 
      we'll generate a personalized PDF detailing your combined results and attachment style tips tailored to your relationship.`,
  [MODE_ONE_TO_N]: `Once you press send, we will separately invite :partners to complete their quizzes. 
      As each person completes the quiz, we'll generate a personalized PDF for each partner detailing your combined results and attachment style 
      tips tailored to your relationship with them.`,
  [MODE_N_TO_M]: `Once you press send, we will invite your partners (:partners) to complete their quizzes. As soon as everyone finishes, 
      we'll generate a joint personalized PDF detailing the combined results and attachment style tips tailored to your group dynamic.`,
};

const inviteeDescConfig: Record<string, string> = {
  [MODE_ONE_TO_ONE]: `Once you press send, we will generate the report with :partners. You will be emailed a personalized PDF 
  detailing your combined results and attachment style tips tailored to your relationship.`,
  [MODE_ONE_TO_N]: `Once you press send, we will generate the report with :partners. We will generate and E-Mail a 
  personalized PDF detailing your combined results and attachment style tips tailored to your relationship.`,
  [MODE_N_TO_M]: `As soon as (:partners) complete their quizzes, we will generate and E-Mail a joint personalized PDF detailing 
  the combined results and attachment style tips tailored to your group dynamic.`,
};

const inviterSumbitTxtMap: Record<string, string> = {
  [MODE_ONE_TO_ONE]: 'Send My Invite to :partners & Share Results',
  [MODE_ONE_TO_N]: 'I Want to Send & Invite Separately',
  [MODE_N_TO_M]: 'I Want To Send & Invite All Partners',
};

const inviteeSumbitTxtMap: Record<string, string> = {
  [MODE_ONE_TO_ONE]: 'I Want to Share My Results With :partners',
  [MODE_ONE_TO_N]: 'I Want to Share My Results With :partners',
  [MODE_N_TO_M]: 'I Want to Share My Results With My Partners',
};

const inviterResultHeadMap: Record<string, string> = {
  [MODE_ONE_TO_ONE]: 'Your attachment style towards :partners is',
  [MODE_ONE_TO_N]:
    'Your Attachment Style towards the people you invited to be sent separately:',
  [MODE_N_TO_M]:
    'Your Attachment Style towards the people you invited to be sent as a group:',
};

const inviteeResultHeadMap: Record<string, string> = {
  [MODE_ONE_TO_ONE]: 'Your attachment style towards :partners is',
  [MODE_ONE_TO_N]: 'Your attachment style towards :partners is',
  [MODE_N_TO_M]: 'Your Attachment Style towards your partners:',
};
