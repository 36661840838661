import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/maladaptive-daydreaming/QuizResult';
import image from '../../assets/images/report/maladaptive-daydreaming.png';

export class MaladaptiveDaydreaming implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score, redirectUrl }: any) {
    return {
      subtitle: 'Your Maladaptive Daydreaming Score:',
      title: `${score}%`,
      description:
        'People who score over 40% typically show signs of suspected maladaptive daydreaming.',
      bulletPoints: [
        'Understand Maladaptive Daydreaming.',
        'Complete breakdown of your score.',
        'More information about steps you can take.',
      ],
      color: '#e66c4c',
      backgroundColor: '#e66c4c',
      redirectUrl,
      image,
    };
  }
}
