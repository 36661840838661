import React from 'react';

interface Props {
  title: string;
  description?: string;
  children: any;
}

const Question: React.FC<Props> = ({ title, description, children }) => {
  return (
    <div className='question'>
      <div className='mb-4'>
        <h5>{title}</h5>
        {description && (
          <p
            className='fs-14'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
      <div className='position-relative'>{children}</div>
    </div>
  );
};

export default Question;
