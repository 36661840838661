import { toast } from 'react-toastify';
import { shareReport } from '../../../libs/api/coupleQuizStatApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface Props {
  onSubmitCallback: (params: any) => void;
}

const useShareReportForm = ({ onSubmitCallback }: Props) => {
  const validationSchema: any = Yup.object().shape({
    terms: Yup.boolean().isTrue('Consent required to proceed.'),
  });

  const onSubmit = (values: any, { setSubmitting }: any) => {
    shareReport(values)
      .then(() => onSubmitCallback(values))
      .catch(({ message }: any) => {
        toast.error(message);
        setSubmitting(false);
      });
  };

  const form = useFormik({
    initialValues: {
      terms: false,
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  return {
    form,
  };
};

export default useShareReportForm;
