import { ReactComponent as AnxiousShape } from '../../assets/images/attachment/anxious-shape.svg';
import { ReactComponent as AvoidantShape } from '../../assets/images/attachment/avoidant-shape.svg';
import { ReactComponent as DisorganizedShape } from '../../assets/images/attachment/disorganized-shape.svg';
import { ReactComponent as SecureShape } from '../../assets/images/attachment/secure-shape.svg';
import anxiousImg from '../../assets/images/attachment/anxious.webp';
import avoidantImg from '../../assets/images/attachment/avoidant.webp';
import disorganizedImg from '../../assets/images/attachment/disorganized.webp';
import secureImg from '../../assets/images/attachment/secure.webp';
import anxiousSmImg from '../../assets/images/attachment/anxious-sm.webp';
import avoidantSmImg from '../../assets/images/attachment/avoidant-sm.webp';
import disorganizedSmImg from '../../assets/images/attachment/disorganized-sm.webp';
import secureSmImg from '../../assets/images/attachment/secure-sm.webp';
import './AttachmentThumb.css';

const AttachmentThumb = ({ attachment }) => {
  const image = getImageConfig(attachment);
  const ShapeElement = getShapeElement(attachment);

  return (
    <div className='attachment-wrapper'>
      <picture>
        <source media='(max-width: 991px)' srcSet={image.small} />
        <img src={image.standard} alt={attachment} />
      </picture>
      <ShapeElement className='shape' />
    </div>
  );
};

const getImageConfig = (key) => config[key]?.image ?? null;

const getShapeElement = (key) => config[key]?.shape ?? null;

const config = {
  anxious: {
    image: { standard: anxiousImg, small: anxiousSmImg },
    shape: AnxiousShape,
  },
  avoidant: {
    image: { standard: avoidantImg, small: avoidantSmImg },
    shape: AvoidantShape,
  },
  disorganized: {
    image: { standard: disorganizedImg, small: disorganizedSmImg },
    shape: DisorganizedShape,
  },
  secure: {
    image: { standard: secureImg, small: secureSmImg },
    shape: SecureShape,
  },
};

export default AttachmentThumb;
