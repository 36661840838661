import React, { useState } from 'react';
import coverImage from '../../../../assets/images/emotional-regulation-shape.png';
import SubmitButton from '../../../SubmitButton';
import './EmotionalDysregulation.css';

interface Props {
  skippable: boolean;
  skip: () => Promise<any>;
  start: () => Promise<any>;
}

const EmotionalDysregulation: React.FC<Props> = ({
  skippable,
  skip,
  start,
}) => {
  const [isStarting, setIsStarting] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);

  const isDisabled = isStarting || isSkipping;

  const onStartHandler = () => {
    setIsStarting(true);
    start().finally(() => setIsStarting(false));
  };

  const onSkipHandler = () => {
    setIsSkipping(true);
    skip().finally(() => setIsSkipping(false));
  };

  return (
    <div className='emotional-dysregulation'>
      <div className='cover'>
        <img src={coverImage} alt='Emotional Regulation' />
      </div>
      <div className='title'>Emotional Regulation</div>
      <div className='content'>
        <p className='mb-2'>
          How you regulate your emotions is closely related to your Attachment
          Style. In this last short section, we have a final set of questions
          for you to answer that cover a wide variety of topics, such as your:
        </p>
        <ul>
          <li>Emotional clarity.</li>
          <li>Goal-directed behavior.</li>
          <li>Impulse control.</li>
        </ul>
        <p>
          Answering these questions gives you a full, comprehensive
          understanding of your profile. We recommend that you do them, but of
          course, you do not have to.
        </p>
      </div>
      <SubmitButton
        className='action'
        isSubmitting={isStarting}
        disabled={isDisabled}
        onClick={onStartHandler}
      >
        Start
      </SubmitButton>
      {skippable && (
        <SubmitButton
          className='action'
          bsColor='light'
          isSubmitting={isSkipping}
          disabled={isDisabled}
          onClick={onSkipHandler}
        >
          Skip this session
        </SubmitButton>
      )}
    </div>
  );
};

export default EmotionalDysregulation;
