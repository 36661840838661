import React from 'react';
import { connect } from 'react-redux';
import { getImageSrc } from '../../../libs/helpers/imageHelper';
import { getSurveyQuestions } from '../../../libs/redux/actions/surveyQuestionsActions';
import useQuizSurvey from './useQuizSurvey';
import QuestionContainer from '../../question-container/QuestionContainer';
import UserAnswerForm from '../forms/UserAnswerForm';
import ValidationError from '../../ValidationError';
import ContinueLaterModal from '../ContinueLaterModal';
import SubmitButton from '../../SubmitButton';
import IQuizSurvey from '../../../interfaces/QuizSurvey';
import ISurvey from '../../../interfaces/Survey';
import './QuizSurvey.css';

const QuizSurvey: React.FC<any> = (props) => {
  const {
    isLoading,
    isSubmitting,
    hasErrors,
    validateQuestion,
    surveyStat,
    questionPagination,
    submit,
    questionIndex,
    setQuestionIndex,
    canContinueLater,
    userAnswers,
    showIntroComponent,
    IntroComponent,
    getIntroProps,
  } = useQuizSurvey(props);

  const quizSurvey: IQuizSurvey = surveyStat.quiz_survey;
  const survey: ISurvey = quizSurvey.survey;

  const title = quizSurvey.name_override || survey.name;
  const description = quizSurvey.desc_override || survey.description;
  const image = quizSurvey.image_override || survey.image;

  const { current_page, per_page, total } = questionPagination;

  return (
    <div className={`quiz-survey ${isLoading ? 'loading' : ''}`}>
      {showIntroComponent() ? (
        <IntroComponent {...getIntroProps()} />
      ) : (
        <React.Fragment>
          <div className='header'>
            <div className='d-flex flex-wrap align-items-center mb-4'>
              {survey.image && (
                <img src={getImageSrc(image)} alt='Survey' className='image' />
              )}
              <h3 className='title'>{title}</h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <hr className='my-5' />
          <QuestionContainer
            focusIndex={questionIndex}
            setFocusIndex={setQuestionIndex}
            totalCount={total}
            countFrom={(current_page - 1) * per_page + 1}
            questions={questionPagination.data.map((question) => {
              const { id, name: title, description, answers } = question;
              const selectedUserAnswer: any = userAnswers.find(
                (x: any) => x.question_id === id,
              );
              const isAnswered = selectedUserAnswer !== undefined;

              return {
                title,
                description,
                isAnswered,
                children: (
                  <React.Fragment>
                    <UserAnswerForm
                      questionId={id}
                      surveyStatId={surveyStat.id}
                      answers={answers}
                      selectedUserAnswer={selectedUserAnswer}
                      disabled={isSubmitting}
                    />
                    {hasErrors && !validateQuestion(question) && (
                      <div className='validation-error'>
                        <ValidationError error='Please select your answer.' />
                      </div>
                    )}
                  </React.Fragment>
                ),
              };
            })}
          />
          <div className='action-wrapper'>
            <div className='d-flex flex-wrap-reverse flex-sm-nowrap align-items-center justify-content-center'>
              {canContinueLater() ? (
                <ContinueLaterModal className='btn btn-light w-100 me-0 me-sm-3 mt-3 mt-sm-0'>
                  Continue later
                </ContinueLaterModal>
              ) : (
                ''
              )}
              <SubmitButton onClick={submit} isSubmitting={isSubmitting}>
                Continue
              </SubmitButton>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = ({ surveyQuestions }: any) => {
  return {
    questionPagination: surveyQuestions.questions,
    userAnswers: surveyQuestions.userAnswers,
  };
};

const mapDispatchToProps = {
  getSurveyQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizSurvey);
