import bgImage from '../../../../assets/images/report/background/bg-limerence.svg';
import SendReportCard from '../../send-report-card/SendReportCard';
import './QuizResult.css';
import '../index.css';

interface Props {
  subtitle: string;
  title: string;
  label: string;
  description: string;
  image?: string;
  bulletPoints?: string[];
  color?: string;
  titleFontSize?: string;
  onSuccessCallback: () => void;
}

const QuizResult: React.FC<Props> = ({
  subtitle,
  title,
  label,
  description,
  image,
  bulletPoints = [],
  onSuccessCallback,
}) => {
  return (
    <div
      className='quiz-results sp-quiz-result'
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className='content flex-column flex-xl-row'>
        <div className='result-wrapper'>
          <span className='subtitle'>{subtitle}</span>
          <h1 className='title'>{title}</h1>
          <span className='description'>
            <strong>{label}</strong>
            <br />
            {description}
          </span>
        </div>
        <div className='send-report-wrapper mb-5 mb-lg-0'>
          <SendReportCard
            imageSrc={image}
            bulletPoints={bulletPoints}
            onSendReportSuccess={onSuccessCallback}
          />
        </div>
      </div>
    </div>
  );
};

export default QuizResult;
