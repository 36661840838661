import React, { useEffect } from 'react';
import { ReactComponent as SquareMinusIcon } from '../../../assets/icons/square-minus.svg';
import { ReactComponent as PlusCircleIcon } from '../../../assets/icons/plus-circle.svg';
import ValidationError from '../../ValidationError';

const PartnerInfoForm = ({
  form,
  partnerLimit,
  partnersOnDefault = 1,
}: any) => {
  const canAddPartner = () => partnerLimit > form.values.partners.length;

  const addPartner = () =>
    form.setFieldValue('partners', [
      ...form.values.partners,
      { name: '', email: '' },
    ]);

  const removePartner = (index: number) => {
    const partners = form.values.partners;
    partners.splice(index, 1);
    form.setFieldValue('partners', partners);
  };

  const getFieldProps = (index: number, field: string) => ({
    className: `form-control ${
      isInvalidField(index, field) ? 'is-invalid' : ''
    }`,
    ...form.getFieldProps(`partners[${index}].${field}`),
  });

  const isInvalidField = (index: number, field: string) => {
    return (
      form.touched?.partners?.[index]?.[field] !== undefined &&
      form.errors?.partners?.[index]?.[field] !== undefined
    );
  };

  useEffect(() => {
    form.setFieldValue('partners', form.values.partners.slice(0, partnerLimit));
  }, [partnerLimit]);

  useEffect(() => {
    for (let i = 0; i < partnersOnDefault; i++) {
      addPartner();
    }
  }, []);

  return form.values.partners.map((_: any, index: number) => (
    <React.Fragment key={index}>
      <div>
        <div className='d-flex align-items-center'>
          {partnerLimit > 1 && <h6>Partner {index + 1}</h6>}
          {index > 0 && (
            <button
              type='button'
              className='btn btn-link ms-auto mb-1 p-0 fs-12'
              onClick={() => removePartner(index)}
            >
              Remove
              <SquareMinusIcon className='ms-2' />
            </button>
          )}
        </div>
        <div className='mb-3'>
          <input
            autoComplete='none'
            placeholder='Name'
            {...getFieldProps(index, `name`)}
          />
          {isInvalidField(index, `name`) && (
            <ValidationError error={form.errors.partners[index].name} />
          )}
        </div>
      </div>
      <div className='mb-3'>
        <input
          autoComplete='none'
          placeholder='Email'
          {...getFieldProps(index, `email`)}
        />
        {isInvalidField(index, `email`) && (
          <ValidationError error={form.errors.partners[index].email} />
        )}
      </div>
      {index === form.values.partners.length - 1 && (
        <div className='d-flex align-items-center'>
          {canAddPartner() && (
            <button
              type='button'
              className='btn btn-link d-flex align-items-center px-0 py-1'
              onClick={addPartner}
            >
              <PlusCircleIcon className='me-2' />
              Add more partners
            </button>
          )}
          {partnerLimit > 1 && (
            <small className='ms-auto'>
              {form.values.partners.length} of {partnerLimit}
            </small>
          )}
        </div>
      )}
    </React.Fragment>
  ));
};

export default PartnerInfoForm;
