import { useNavigate } from 'react-router-dom';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye.svg';
import { ROMANTIC_PARTNERS } from '../../../../libs/helpers/quizHelper';
import {
  removeQuizAccessToken,
  removeQuizSessionToken,
} from '../../../../libs/services/quizAccessService';
import ShareReportForm from '../../forms/ShareReportForm';
import './QuizResult.css';

interface Props {
  title: string;
  description: string;
  resultHeader: string;
  diagram: string;
  results: any;
  submitText: string;
  redirectUrl?: string;
}

const QuizResult: React.FC<Props> = ({
  title,
  description,
  resultHeader,
  diagram,
  results,
  submitText,
  redirectUrl,
}) => {
  const singleResult = results.length === 1;
  const navigate = useNavigate();

  const onSubmitCallback = () => {
    removeQuizTokens();
    redirect();
  };

  const onCancelCallback = () => {
    removeQuizTokens();
    navigate('/');
  };

  const removeQuizTokens = () => {
    removeQuizAccessToken(ROMANTIC_PARTNERS);
    removeQuizSessionToken();
  };

  const redirect = () => {
    window.location.href = redirectUrl ?? '/';
  };

  const getResultLabel = (): string => {
    const attachment = results[0]['type'] ?? 'default';

    const map: any = {
      secure: 'Secure',
      disorganized: 'Fearful-Avoidant / Disorganized',
      dismissing: 'Avoidant / Dismissive',
      anxious: 'Anxious / Preoccupied',
    };

    return map[attachment] ?? 'N/A';
  };

  return (
    <div className='rom-partners-result'>
      <div className='left-panel'>
        <div className='title'>{title}</div>
        <div className='description'>{description}</div>
        <ShareReportForm
          onSubmitCallback={onSubmitCallback}
          onCancelCallback={onCancelCallback}
          submitText={submitText}
        />
      </div>
      <div className='right-panel'>
        <div className='header'>
          <div className='result-badge'>
            <div className='d-flex align-items-center'>
              <EyeIcon className='me-2' />
              Only visible to you until shared
            </div>
          </div>
          <div className='title'>{resultHeader}</div>
          {singleResult && (
            <div className='result-label'>{getResultLabel()}</div>
          )}
        </div>
        <div className='text-center'>
          <img src={diagram} alt='Diagram' className='diagram' />
        </div>
        <div className='partners'>
          {singleResult
            ? results.map(({ color, result }: any) => (
                <div className='partner-card'>
                  <div
                    className='bullet'
                    style={{ backgroundColor: `rgb(${[...color]})` }}
                  />
                  Score Romantic: Anxiety: {result['anxiety']} / Avoidance:{' '}
                  {result['avoidance']}
                </div>
              ))
            : results.map(({ type, color, partner }: any) => (
                <div className='partner-card'>
                  <div
                    className='bullet'
                    style={{ backgroundColor: `rgb(${[...color]})` }}
                  />
                  Your attachment dynamic towards {partner} is {type}.
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default QuizResult;
