import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getResults } from '../../../libs/api/quizStatApi';
import {
  removeQuizAccessToken,
  removeQuizSessionToken,
} from '../../../libs/services/quizAccessService';
import QuizLayout from '../../../components/layout/QuizLayout';
import Loader from '../../../components/quiz/loader/Loader';
import env from '../../../env';
import './QuizCompletedPage.css';

const QuizCompletedPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [payload, setPayload]: [any, any] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = () => {
    setIsLoading(true);
    getResults()
      .then((payload) => {
        setPayload(payload);
        setIsLoading(false);
        removeQuizTokens();
      })
      .catch(({ message }) => {
        removeQuizTokens();
        navigate('/');
        toast.error(message);
      });
  };

  const removeQuizTokens = () => {
    removeQuizAccessToken(slug);
    removeQuizSessionToken();
  };

  return (
    <QuizLayout showFooter={!isLoading}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='quiz-completed-page'>
          <div className='col-md-6'>
            <h2>{payload?.message}</h2>
            <Link to={env.wordpressUrl} className='btn btn-secondary w-50 mt-5'>
              Home
            </Link>
          </div>
        </div>
      )}
    </QuizLayout>
  );
};

export default QuizCompletedPage;
