import { useParams } from 'react-router-dom';
import * as quizHelper from '../../../libs/helpers/quizHelper';

interface OpenGraphConfig {
  title: string;
}

const useOpenGraph = (): OpenGraphConfig => {
  const { slug = '' } = useParams();

  const defaultConfig: OpenGraphConfig = {
    title: 'The Attachment Project | Result',
  };

  const config: { [key: string]: OpenGraphConfig } = {
    [quizHelper.EMOTIONAL_REGULATION]: {
      title: 'The Attachment Project | Emotional Regulation Survey',
    },
    [quizHelper.MALADAPTIVE_DAYDREAMING]: {
      title: 'The Attachment Project | Maladaptive Daydreaming Survey',
    },
    [quizHelper.MALADAPTIVE_SCHEMA]: {
      title: 'The Attachment Project | Maladaptive Schema Survey',
    },
    [quizHelper.SELF_ESTEEM]: {
      title: 'The Attachment Project | Self Esteem Result',
    },
    [quizHelper.SUPERPOWERS]: {
      title: 'The Attachment Project | Superpowers Result',
    },
    [quizHelper.LIMERENCE]: {
      title: 'The Attachment Project | Limerence Result',
    },
  };

  return config[slug] || defaultConfig;
};

export default useOpenGraph;
